import dayjs from "dayjs";
import { partCode } from "@/common/constant";
// import { useI18n } from 'vue-i18n';
import { useI18n } from "vue-i18n/dist/vue-i18n.cjs";
import _ from "lodash";
import heic2any from "heic2any";
import localeJson from "@/locale/en.json";

const capitalizeFirstLetter = (str) => {
    if (str) {
        return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
    }
    return str;
};

function capitalizeWords(str) {
    return str
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

const genderFullWord = (str) => {
    const gender = {
        m: "Male",
        f: "Female",
        male: "Male",
        female: "Female",
        Male: "Male",
        Female: "Female",
    };
    return gender[str];
};

const yesOrNo = (boolean) => {
    return boolean ? `${t("common.Yes")}` : `${t("common.No")}`;
};

const openJsonCopy = () => {
  const finalRes = _.chain(localeJson)
      .flatMap(singleGroup => _.map(singleGroup, (value, key) => `${key} \t ${value}`)) // Removed \n
      .join('\n')
      .value();
    navigator.clipboard.writeText(finalRes);
};

const getAge = (date) => {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

const cleanObject = (obj) => {
    for (let key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
            delete obj[key];
        }
    }
    return obj;
};

const getFileKey = (fileUrl) => {
    const split = fileUrl.split("/")
    const length = split.length
    return `${split[length-3]}/${split[length-2]}/${split[length-1]}`
}

const getDate = (date) => {
    const cdate = date ? new Date(date) : new Date();
    let strYear = cdate.getFullYear();
    let strMonth =
        cdate.getMonth() < 9 ? "0" + (cdate.getMonth() + 1) : cdate.getMonth() + 1;
    let strDate = cdate.getDate() < 10 ? "0" + cdate.getDate() : cdate.getDate();

    return strYear + "-" + strMonth + "-" + strDate;
};

const getDateFormat = (date) => {
    if(!date) return "";

    let d = changeDateFromUTC(date);
    return dayjs(d).format("DD MMM YYYY");
};

const getDateFormatUserUTC = (date) => {
    if(!date) return "";

    let d = changeDateFromUTC(date);
    return dayjs(d).format("YYYY-MM-DD");
};

const getDateFormat2 = (date) => {
    if(!date) return "";
    return dayjs(date).format("YYYY.MM.DD");
};

const getUTCDateFormat = (utcString) => {
    if(!utcString) return "";
    // UTC 문자열을 JavaScript Date 객체로 변환
    const date = new Date(utcString);

    // Intl.DateTimeFormat을 사용하여 형식 변경
    const formattedDate = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}`;

    return formattedDate;
};

//process.env.VUE_APP_API_URI
const timezoneOffsets = (timezone) => {
    if(timezone == "KR"){
        //KR KST 9          한국표준
        return 9;
    }else if(timezone == "DE"){
        //DE CET, CEST 1    중앙유럽
        return 1;
    }else if(timezone == "UK"){
        return 0;
    }else if(timezone == "US"){
        //US EST, EDT = -5  동부
        //US CST, CDT = -6  중부
        //US MST, MDT = -7  산안
        //US PST, PDT = -8  태평양
        return -7;
    }else if(timezone == "AU"){
        //AU EST = 10       동부
        //AU CST = 9.5      증부
        //AU WST = 8        서부
        return 10;
    }else{
        return 0;
    }
}

/**
 * 
 */
const changeDateFromUTC = (date) => {
    let utcDate = new Date(date);

    // 사용자의 브라우저 시간대 가져오기
    // const userOffset = -(new Date().getTimezoneOffset()) / 60;

    // 코드로 시간대 가져오기
    const userOffset = timezoneOffsets(process.env.VUE_APP_REGION);

    // 사용자의 시간대에 해당하는 오프셋 계산
    const userOffsetMinutes = userOffset * 60;

    const userTime = new Date(utcDate.getTime() + userOffsetMinutes * 60 * 1000);

    return userTime;
}


const getDateFormatWithTime = (date) => {
    return dayjs(date).format("DD MMM YYYY HH:mm:ss");
};

/**
 * 정렬 함수
 * @param {String} key           : 정렬할 키
 * @param {Array} targets        : 정렬할 데이터
 * @param {Array} targetsOrders  : order값 배열 (true인경우 오름차순 / false인경우 내림차순)
 */
const sortBy = (key, targets, targetsOrders) => {
    // 대소문자 구분 & 숫자 구분
    targets.sort((a, b) => {
        let valueA = a[key];
        let valueB = b[key];
        // 숫자인 경우
        if (!isNaN(valueA) && !isNaN(valueB)) {
            // isNaN("")은 false로 나옴. 로직 수정하거나 _.orderBy 사용 권장
            valueA = parseFloat(valueA);
            valueB = parseFloat(valueB);
            return targetsOrders[key] ? valueA - valueB : valueB - valueA;
        }
        valueA = typeof valueA === 'string' ? valueA.toLowerCase() : valueA;
        valueB = typeof valueB === 'string' ? valueB.toLowerCase() : valueB;
        // 문자인 경우
        if (targetsOrders[key]) {
            return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        } else {
            return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
        }
    });
    targetsOrders[key] = !targetsOrders[key];

    // targets.sort((a, b) => {
    //     if (targetsOrders[key]) {
    //         return a[key] < b[key] ? -1 : a[key] > b[key] ? true : false; // 오름차순(문자)
    //     } else {
    //         return a[key] > b[key] ? -1 : a[key] < b[key] ? true : false; // 내림차순(문자)
    //     }
    // });
    // targetsOrders[key] = !targetsOrders[key];
};

const getUid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

/**
 * 긴 글인 경우 뒷 부분은 ... 으로 처리
 * @param {string} str 
 * @param {number} length 
 * @returns 
 */
const shortenString = (str, length) => {
    return str.length > length ? str.slice(0, length) + "..." : str;
}


/**
 * NaN값일 때 0으로 치환하는 함수
 * @param {number} value
 */
const checkNaN = (value) => {
    if (value) {
        return value;
    } else {
        return 0;
    }
};

/**
 * 파일 확장자가 pdf인지 image인지 확인하는 함수
 * @param {string} fileUrl   : 파일 url
 * @param {string} extension : 체크할 기준 확장자 ( pdf | image)
 * @returns
 */
const checkExtension = (fileUrl, extension) => {
    if (!fileUrl) return false;
    let ext = /(\.pdf)$/i.test(fileUrl) ? "pdf" : "image";

    if (ext == extension) {
        return true;
    } else {
        return false;
    }
};

/**
 * 로딩시 사용하는 딜레이 함수
 * @param {function}      func    : callback 함수
 * @param {number}        delay   : 딜레이할 시간 (millisecond)
 * @param {ref(boolean)}  loading : 로딩시작과 종료를 체크할 변수
 */
const setTimer = (func, delay, loading) => {
    loading.value = true;
    setTimeout(() => {
        func();
        loading.value = false;
    }, delay);
};

const forceDownload = (blob, filename) => {
    var a = document.createElement("a");
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
};

const downloadResource = (url, filename) => {
    if (!filename) filename = url.split("\\").pop().split("/").pop();
    const fileExt = filename.split('.').pop();

    if (fileExt === "heic") {
        downloadHeicResource(url, filename);
    } else {
        fetch(url, {
            headers: new Headers({
                Origin: location.origin,
            }),
            mode: "cors",
        })
            .then((response) => response.blob())
            .then((blob) => {
                let blobUrl = window.URL.createObjectURL(blob);
                forceDownload(blobUrl, filename);
            })
            .catch((e) => console.error(e));
    }
};

/**
 * heif to jpg
 * @param {string} url 
 * @param {string} filename 
 */
const downloadHeicResource = (url, filename) => {
    if (!filename) filename = url.split("\\").pop().split("/").pop();
    fetch(url, {
        headers: new Headers({
            Origin: location.origin,
        }),
        mode: "cors",
    })
        .then((response) => response.blob())
        .then((blob) =>
            heic2any({
                blob,
                toType: "image/jpeg",
                quality: 1
            })
        )
        .then((conversionResult) => {
            const blobUrl = window.URL.createObjectURL(conversionResult);
            forceDownload(blobUrl, filename.replace("original_", "").replace(".heic", ".jpg"));
        })
        .catch((e) => console.error(e));
}

const getFilenameFromUrl = (url) => {
    return url.substring(url.lastIndexOf('/') + 1)
}

const blobToFile = (data) => {
    const blobBin = atob(data.split(",")[1]);
    let array = [];
    for (var i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/png" }); // Blob 생성
};
      

const getImageFromWebUrl = (url) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error(`load ${url} fail`));
        img.src = url;
    });
};

const getImage = (localUrl) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = localUrl;
        img.onerror = () => reject(new Error(`load ${localUrl} fail`));
        img.onload = () => resolve(img);
    });
}

/**
 * 배열에서 선택한 인덱스 찾기
 * @param {Array} arr
 * @param {Object} target
 */
const getFindIndex = (arr, target) => {
    return arr.findIndex(o => o === target);
};

const changePartName = (code) => {
    var part = partCode[code];

    if (part) {
        return t(partCode[code].name) + " - " + t(partCode[code].type);
    } else {
        return "";
    }
};

let usei18n = null;
const t = (code) => {
    if (!usei18n) {
        try{
            const {t} = useI18n();
            usei18n = {t: t};
        }catch(e){
            console.error("usei18n error :",code);

            return "";
        }
 
    }

    return usei18n.t(code);
};

const toFixedNumber = (value, fractionDigits) => {
    return +value.toFixed(fractionDigits || 2);
};

/**
 * S3에 저장된 이미지 URL 가져오기 (CORS 문제로 인해 이미지 URL에 쿼리스트링 추가)
 * @param {string} url 
 */
const getS3Url = (url) => {
    return `${url}?v=${new Date().getTime()}`;
}

const useLoadImage = (url) => {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => {
            resolve(img);
        };
        img.onerror = (err) => {
            reject(err);
        };
        img.src = url +"?v="+new Date().getTime();
    });
}

const timeout = async (promise, ms) => {
    let timer;
    const res = await Promise.race([
        promise,
        new Promise(resolve => {
            timer = setTimeout(() => resolve('timeout'), ms);
        })
    ]).finally(() => clearTimeout(timer));

    if (res === 'timeout') {
        throw new Error(`${ms}ms timeout`);
    }
    return res;
}

const sleep = (sec) => {
    return new Promise(resolve => setTimeout(resolve, sec));
} 

// 재시도 함수
const retryAction = async(action, retryCheck, maxAttempts, interval) => {
    let currentAttempt = 1;
    let currentController = null;
  
    async function tryAction() {
        if (currentController) {
            currentController.abort();
        }
        currentController = new AbortController();
        try {
            const res = await action();
            if (retryCheck(res) && currentAttempt < maxAttempts) {
            console.error(`재시도 ${currentAttempt}/${maxAttempts}`);
            await new Promise(resolve => setTimeout(resolve, interval));
            currentAttempt++;
    
            return tryAction();
            } else {
            return res;
            }
        } catch (error) {
            if (currentAttempt < maxAttempts) {
            console.error(`재시도 ${currentAttempt}/${maxAttempts}: ${error.message}`);
            await new Promise(resolve => setTimeout(resolve, interval));
            currentAttempt++;
    
            return tryAction();
            } else {
            throw new Error(`재시도 ${maxAttempts}번 모두 실패`);
            }
        }
    }
  
    return tryAction();
}

const changeArrayToMap = (array, key) => {
    let map = new Map();
    array.forEach((item)=>{
        map.set(item[key], item);
    })

    return map;
}

const changeExtensionUpper = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    const extension = fileName.slice(lastDotIndex + 1);
    const newExtension = extension.toUpperCase();

    return fileName.slice(0, lastDotIndex + 1) + newExtension;
}

const isIP = (url) => {
    const ipPattern = /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}$/;
    return ipPattern.test(url);
}

const isScrolled = () => {
    return document.documentElement.scrollWidth > document.documentElement.clientWidth;
}

/**
 * "YYYY-MM" 형식의 년월을 반환합니다
 * @param {Date | undefined} [d]
 */
const getYearMonth = (d) => {
    // 현재 날짜를 가져옵니다
    const date = d || new Date();

    // 년도와 월을 가져옵니다
    const year = date.getFullYear(); // 마지막 두 자리를 가져옵니다
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고, 두 자리로 맞춥니다

    // YY-MM 형식으로 출력합니다
    const formattedDate = `${year}-${month}`;

    return formattedDate;
}

export {
    capitalizeFirstLetter,
    capitalizeWords,
    genderFullWord,
    yesOrNo,
    getAge,
    getDate,
    getFileKey,
    cleanObject,
    getUid,
    shortenString,
    sortBy,
    checkNaN,
    checkExtension,
    setTimer,
    getDateFormat,
    getDateFormat2,
    getDateFormatUserUTC,
    getUTCDateFormat,
    getDateFormatWithTime,
    downloadResource,
    getFilenameFromUrl,
    getImageFromWebUrl,
    getImage,
    getFindIndex,
    changePartName,
    openJsonCopy,
    t,
    blobToFile,
    toFixedNumber,
    getS3Url,
    useLoadImage,
    sleep,
    timeout,
    retryAction,
    changeArrayToMap,
    changeExtensionUpper,
    isIP,
    isScrolled,
    getYearMonth
};
