import { defineStore } from 'pinia';
import AuthApi from '@/api/api.auth';

import { AuthModel, AuthEmailCheckModel, AuthStoreModel, AuthTokenModel } from '@/types/type.auth';

import { jwtDecode } from 'jwt-decode';
import { localStorageManager } from '@/common/util/localStorageManager';

export const useAuthStore = defineStore({
    id: 'auth',
    state: () : AuthStoreModel => {
      return {
        returnUrl: '',
        userAuth: JSON.parse(localStorageManager.getUserAuth() || '{}'),
        emailCheckData: null,
        email : '',
        doctorSeq : 0,
      }
    },
    actions: {
      async login(params:object):Promise<Object> {
        try {
          const data:AuthModel = await AuthApi.login(params);

          if(data.success){
            const jsonAuth = JSON.stringify(data);
            localStorageManager.setUserAuth(jsonAuth);
            localStorageManager.setExpiryDate(""+(new Date().getTime()+data.expiryDuration));

            this.userAuth = data;
          }
          return data;
        } catch (error) {
          return {};
        }
      },

      setEmailSave(email:string){
        localStorageManager.setEmail(email);
      },

      getEmail(){
        return localStorageManager.getEmail();
      },

      getDoctorSeq(){
        try {
          const data:AuthModel = JSON.parse(localStorageManager.getUserAuth() || '{}');
          const jwt:AuthTokenModel = jwtDecode(data.accessToken);  
          return jwt.userSeq;
        } catch (error) {
          return null;
        }     
      },

      async requestEmailNumberCheck(email:string, language: 'en' | 'de'):Promise<AuthEmailCheckModel> {
        this.email = email;
        const data = await AuthApi.requestEmailNumberCheck(email, language);
        this.emailCheckData = data;
        return data;
      },      

      async updateDoctorPassword(pwd:string):Promise<Object> {
        const params = {
          "email" : this.email,
          "password" : pwd,
          "checkNum" : this.emailCheckData?.checkNum
        }

        const data = await AuthApi.updateDoctorPassword(params);
        return data;
      },      

      setReturnUrl(url:string){
        this.returnUrl = url;
      },
    }
});
