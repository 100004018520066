import { defineStore } from 'pinia';
import { DoctorModel, DoctorStoreModel } from '@/types/type.doctor';
import DoctorApi from '@/api/api.doctor';
import { initUserData } from '@/controller/DataController';
import { cleanObject } from '@/common/util';
import { localStorageManager } from '@/common/util/localStorageManager';
// import { getUserData } from '@/controller/CommonController';

export const useDoctorStore = defineStore('doctor', {
    state: () : DoctorStoreModel => ({
      doctors: null,
      doctor: initUserData(),
      loading: false,
      error: null,
    }),

    getters:{
      doctorSeq():number | null{
        return this.doctor ? this.doctor.doctorSeq : null;
      },
      clinicSeq():number | null{
        return this.doctor ? this.doctor.clinicSeq : null;
      }
    },

    actions:{
      async getDoctors(clinicSeq:number):Promise<DoctorModel[]>{

        const data = await DoctorApi.getDoctors(clinicSeq);
        this.doctors = [];
        this.doctors = data;
        return data;
      },

      async getDoctor(doctorSeq:number, isHasCheck:boolean):Promise<DoctorModel>{
        if(isHasCheck){
          if(this.doctor){
            if(this.doctor.firstName){
              return this.doctor;
            }
          }
        }

        const data = await DoctorApi.getDoctor(doctorSeq);
        this.doctor = data;
        try{
          localStorageManager.setDoctorSeq(""+this.doctor.doctorSeq);
          localStorageManager.setClinicSeq(""+this.doctor.clinicSeq);
          localStorageManager.setProfileImgUrl(""+this.doctor.profileImgUrl);
        }catch(error){
          console.error(error);
        }
        return data;
      },

      async updateDoctor(doctor:DoctorModel):Promise<DoctorModel>{
        const data = await DoctorApi.updateDoctor(doctor);
        this.doctor = {
          ...this.doctor,
          ...cleanObject(data)
        }
        return data;
      }

      
    }
});
