import ko from '@/locale/ko.json'
import en from '@/locale/en.json'
import de from '@/locale/de.json'
import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs'
import { localStorageManager } from '@/common/util/localStorageManager'
import { config } from '@/common/constant'
// import { createI18n } from 'vue-i18n'

const messages = {
    'ko' : ko,
    'en' : en,
    'de' : de
}

const localStorageLocale = localStorageManager.getUserLocale();

const initLocale = () => {
    if (localStorageLocale) {
        return localStorageLocale;
    } 

    const browserLocale = window.navigator.language.substring(0, 2);
    if (config.TEST_MODE && browserLocale === 'ko') {
        return browserLocale;
    }

    // 언어 추가시 브라우저 언어선택 테스트하기
    switch (browserLocale) {
        case 'en':
            return 'en';
        case 'de':
            return 'de';
        default:
            return 'en';
    }
}

export const i18n = createI18n({
    legacy: false, 
    locale: initLocale(),
    messages,
});

export function changeI18nLanguage(language) {
    i18n.global.locale.value = language;
}