import { localStorageManager } from "@/common/util/localStorageManager";

export const getImageFormData = ({ file, logFlag = false, doctorSeq, patientSeq, spotSeq, clinicSeq }) => {
  const formData = new FormData();
  formData.append("doctorSeq", doctorSeq);
  formData.append("patientSeq", patientSeq);
  formData.append("spotSeq", spotSeq);
  formData.append("file", file);
  formData.append("logFlag", logFlag);
  formData.append("clinicSeq", clinicSeq);
  return formData;
}

export const initUserData = () => {
  return {
    doctorSeq: Number(localStorageManager.getDoctorSeq()),
    clinicSeq: Number(localStorageManager.getClinicSeq()), 
    deleteFlag: 0,
    updateDate: "",
    addDate: "",
    email: "",
    profileImgUrl: String(localStorageManager.getProfileImgUrl()),
    phone: "",
    licensesNumber: "",
    adminFlag: false,
    firstName: "",
    lastName: "",
    mobileSeq: 0,
    questionFormSeq: 0,
    patientCnt: 0,
    spotCnt: 0,
    spotImgCnt: 0,
    tbpImgCnt: 0,
    pathologyCnt: 0,
    clinicalDxCnt: 0,
    emergencyContract: "",
    birth: "",
    providerNumber: "",
    specialtyCode: "",
    privatePhone: ""
  }
}

export const initClinicData = () => {
  return {
    clinicSeq: Number(localStorageManager.getClinicSeq()),
    deleteFlag: 0,
    updateDate: "",
    addDate: "",
    clinicName: "",
    websiteUrl: "",
    bannerImgUrl: "",
    address: "",
    memberCnt: 0,
    adminSeq: 0,
    mobileSeq: 0,
    questionFormSeq: 0,
    tbpTemplateNum: 0,
    tbpRegType: Number(localStorageManager.getTbpRegType()),
    street: "",
    postCode: "",
    city: "",
    country: "",
    state: "",
    phone: ""
  }
}

export const initPatientData = () => {
  return{
    doctorSeq: Number(localStorageManager.getDoctorSeq()),
    clinicSeq: Number(localStorageManager.getClinicSeq()),
    deleteFlag: false, 
    updateDate: "", 
    addDate: "", 
    firstName: "", 
    lastName: "", 
    gender: "", 
    birth: "", 
    age: 0,
    email: "", 
    phone: "", 
    emergencyContract: "", 
    skinType: "", 
    ethnicity: "", 
    melanomaHisFlag: false, 
    melanomaFamilyHisFlag: false, 
    nonMelanomaFlag: false, 
    street: "", 
    postCode: "", 
    city: "", 
    country: "", 
    state: "", 
    profileUrl: "", 
    consentFlag: false, 
    consentSignImgUrl: "", 
    questionFlag: false, 
    patientSeq: Number(localStorageManager.getPatientSeq()),
    healthId: "",
    questionFormSeq: 0,
    consentSeq: 0
  }
}